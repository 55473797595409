@import '../variables';
.home {
    .rowHome {
        width: 100%;
        margin-top: 20px;
      }

    .colHeight {
        height: 250px;
    }

    .colHeight2 {
        height: 450px;
    }

    .colHeight3 {
        height: 90px;
    }

    .rowMargin {
        margin-top:10px;
    }

    .colRowTop {
        width:100%;
        height:30px;
        font-size:14px;
    }

    .colRowDown {
        width:100%;
        height:70px;
        font-size:35px;
        text-align: right;
    }

    .rowText {
        margin: 12px 0;
    }

    .rowColText {
        color: '#00CEC3';
        font-size: 10px;

    }

    .rowColText2 {
        font-size: 10px;
    }

    .span {
        font-size: 20px;
        margin-top: 20px;
        margin-left: 10px;
    }

}

