@import '../variables';

.list-history-charger {
    .btn-graph {
        margin-right: 35px;
    }
    .text-csv {
        padding: 0 21px 0 21px;
    }

    .title-info {
        font-size: 18px;
    }

    .ant-picker-range {
        width: 100%;
    }

    .type-charger {
        font-size: 9px;
    }

    .btn-search-city {
        align-items: center;
        display: flex;
        justify-content: end;
        .ant-form-item {
            margin-top: 14px;
            margin-bottom: 0 !important;
            .text-search {
                color: $btn-primary;
            }
        }
    }

    .detail-history {
        .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
            margin: 0!important;
        }
        .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
            //background-color: #ffffff;
            //border-radius: 6px 6px 0 0;
            //margin-left: 0!important;
            border: none;
            background: none;
        }
        .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active{
            border-bottom: 2px solid #34C2C2;
            //border-left: none;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
            color: #34C2C2;
        }
        .ant-tabs-tab{
            font-weight: 500;
        }
        .no-bold-label {
            label {
                font-family: "KozGoPr6", serif;
            }
            .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label{
                padding: 0!important;
            }
        }
    }

    .ant-card-bordered {
       min-height: 100%;
    }

}

.rbc-toolbar {
    justify-content: space-between !important;
    .time {
        width: 66px;
    }
    .rbc-btn {
        width: 196px;
    }
}

@media screen and (min-width: 767px) and (max-width: 998px) {
    .title-history {
        font-size: 16px;
    }
}

@media screen and (min-width: 1200px) {
    .card-title {
        font-size: 21px;
    }
}





