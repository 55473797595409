@import '../variables';
.login-form-container {
    background: $background-white;
    display: flex;
    justify-content: center;
    .img-logo {
        max-width: 320px;
        margin-bottom: 25px;
    }
    .height-login {
        height: 100vh;
    }
    .width-login {
        width: 40%;
    }
    .row-login {
        width: 100%;
        align-items: center;
    }
    .image-login {
        width: 100%;
        height: 100%;
    }
    .col-image {
        width: 60%;
        height: 100vh;
    }
    .box-shadow-login {
        border-radius: 10px;
        box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    }
    .box-shadow-button-login {
        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    }
    .input-login {
        border-radius: 5px;
        padding: 6px 12px 6px 12px;
    }
    .text-validate {
        color: $red-3;
    }
    .text-success-validate {
        color: $green-3;
    }
    .text-ev-logo {
        margin-bottom: 15px;
    }
    .title-link-forgot {
        color: $text-grey;
    }
    .title-link-forgot:hover {
        color: $text-black;
        text-decoration: underline;
    }
    .tab-focus-link:focus {
        .focus-link {
            color: $text-black;
            text-decoration: underline
        }
    }
    .block-input-password {
        margin-bottom: 6px;
    }
    .btn-login {
        margin-top: 20px;
        width: 100%;
        background-color: $background-button;
        border: none;
        color: $text-black;
    }
    .btn-login:focus {
        border: 1px solid $text-black;
    }
    .title-forgot-pw {
        margin-top: -10px;
        color: $title-black;
        font-family: 'KozGoPr6-Bold', serif;
        font-size: 25px;
        margin-left: 20px;
    }
    .title-send-mail {
        font-size: 11px;
        text-align: center;
        color: $gray-4;
        margin-top: 5px;
    }
    .email-reset {
        color: $btn-primary;
    }
    .email-notification {
        color: $text-blue;
    }
    //.ant-input:placeholder-shown {
    //    text-align: center;
    //}
    @media (max-width: 768px) {
        .title-forgot-pw {
            font-size: 20px;
        }
    }
    @media (max-width: 576px) {
        .title-forgot-pw {
            font-size: 12px;
            margin-left: 10px;
        }
        .back-reset {
            width: 20px;
            height: 20px;
        }
        .title-link-forgot {
            font-size: 11px;
        }
        .text-ev-logo {
            width: 150px;
        }
        .img-logo {
            width: 150px;
        }
    }
}